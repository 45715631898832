import React from 'react';
import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom'

import './assets/css/bootstrap.css'; // Use this version of Bootstrap, there is compatibility issues with the newer one which conflicts with our build (for now)

import './assets/css/global.scss';

import Home from './pages/Home/index'


const App = () => {
  return (
      <div>
        <Router>
          {/* <Layout> */}
            <Routes>
              {/* <Route path={'/AboutUs'} component={AboutUs}></Route>
              <Route path={'/ContactUs'} component={ContactUs}></Route> */}
              <Route path={'/'} element={<Home/>}></Route>
              {/* <Home/> */}
            </Routes>
          {/* </Layout> */}
        </Router>
      </div>
    );
}

export default App;
